















































































































































































































































    import PageHeader from '~/components/app/page-header.vue'
    import {Component, Vue} from "vue-property-decorator";
    import {
        AddOrder,
        AddOrderCourse,
        Course,
        NoteLanguage,
        noteLanguages,
        OrderStatus
    } from "~/components/data-class/data-class";
    import statusOptions from "~/components/order/status";
    import {sfLookup, updateLocations} from "~/components/sf-express/sf-location-storage";
    import {createRequest} from "~/utils/network-request";
    import CourseData from "~/components/course/course-data";
    import {ClassSchedule, OfflineClass} from '~/components/course/offline-course-model';
    import {Dict} from "~/utils/misc";
    import OfflineClassStructuredDisplay from "~/components/course/offline-class-structured-display.vue";

    @Component({
    components: {
        PageHeader,
        OfflineClassStructuredDisplay
    }
})
export default class OrderCreator extends Vue {
    name: "order-creator"

    addOrderModel: AddOrder = new AddOrder()
    statusOptions = statusOptions

    // shipping
    sfExpressOptions: { value: string, title: string }[] = []

    addCourseDialogVisible = false
    addOrderCourse: AddOrderCourse = new AddOrderCourse()
    selectedCourse: Course = new Course()
    selectedClassId: number = -1
    selectedClasses: Dict<OfflineClass> = {}
    editingCourseId: string = ''
    selectedLanguage: NoteLanguage = NoteLanguage.NONE

    addOrderError: string = ''
    ClassSchedule = ClassSchedule
    NoteLanguage = NoteLanguage
    noteLanguages = noteLanguages

    get selectedCourses() {
        return this.addOrderModel.courses.map((course) => CourseData.getCourse(course.course_id))
    }

    async created() {
        await updateLocations()
        this.sfExpressOptions = Object.keys(sfLookup).map((code) => {
            return {value: code, title: sfLookup[code].address}
        })
    }

    async backClicked() {
        await this.$router.push('/orders')
    }

    createClicked() {
        this.addOrderError = ''

        let errorStrings: string[] = []

        if (this.addOrderModel.courses.length == 0) {
            errorStrings.push('Please add at least 1 course')
        }

        if (this.addOrderModel.member_id == '') {
            errorStrings.push('Please select member')
        }

        this.addOrderModel.price = this.addOrderModel.courses.reduce((cumulate, current) => {
            cumulate += current.price
            return cumulate
        }, 0)

        if (this.addOrderModel.price == 0) {
            this.addOrderModel.status = OrderStatus.PAID
        }

        if (this.addOrderModel.waybill_number != '' && this.addOrderModel.waybill_number.length != 12) {
            errorStrings.push('Waybill number must be of length 12')
        }

        if (this.addOrderModel.phone != '' && this.addOrderModel.phone.length != 8) {
            errorStrings.push('Phone number must be of length 8')
        }

        if (this.addOrderModel.waybill_number != '' && this.addOrderModel.phone != '' && this.addOrderModel.shipping_code == '') {
            errorStrings.push('Please select SFExpress point')
        }

        for (const courseId of Object.keys(this.selectedClasses)) {
            this.addOrderModel.offline_data[courseId] = {
                name: '',
                email: '',
                phone: '',
                time_slot: this.selectedClasses[courseId].id
            }

            if (this.selectedClasses[courseId].language === NoteLanguage.EITHER && (this.addOrderModel.languages[courseId] === undefined)) {
                errorStrings.push('Please select note language')
                // Reset
                this.addOrderModel.offline_data = {}
            }
        }

        if (errorStrings.length == 0) {
            const mid = this.addOrderModel.member_id

            const courseIds = this.addOrderModel.courses.map((c) => c.course_id)
            Object.keys(this.addOrderModel.languages).forEach((cid) => {
                if (!courseIds.includes(cid))
                    delete this.addOrderModel.languages[cid]
            })

            createRequest('/courses/order/member/' + mid + '/force', 'post', {}, this.addOrderModel).send()
                .then(() => {
                    this.addOrderModel = new AddOrder()
                    this.addCourseDialogVisible = false
                    this.$router.push('/orders')
                })
        }
        else {
            this.addOrderError = errorStrings.join(', ')
        }
    }

    addCourseClicked() {
        this.addCourseDialogVisible = true
    }

    languageClicked(course: string, language: NoteLanguage) {
        this.selectedLanguage = language
    }

    cancelClicked() {
        // Reset popup.
        this.resetPopup()

        this.addCourseDialogVisible = false
    }

    addCoursePopupClicked() {

        if (this.selectedCourse.offline && this.selectedClassId === -1) {
            this.$message.error('Please select a class!')
            return
        }

        const selectedClass = this.selectedCourse.offline_data.classes.find((c) => c.id === this.selectedClassId)
        if (((!this.selectedCourse.offline && this.selectedCourse.language === NoteLanguage.EITHER) || (this.selectedCourse.offline && selectedClass && selectedClass.language === NoteLanguage.EITHER)) &&
            ![NoteLanguage.CHINESE, NoteLanguage.ENGLISH].includes(this.selectedLanguage) &&
            this.addOrderCourse.note) {
            this.$message.error('Please select note language!')
            return
        }

        let existingIndex = this.addOrderModel.courses.findIndex((c) => c.course_id === this.addOrderCourse.course_id)
        let editingIndex = this.addOrderModel.courses.findIndex((c) => c.course_id === this.editingCourseId)

        if (this.editingCourseId === '') {
            if (existingIndex !== -1) {
                this.addOrderModel.courses.splice(existingIndex, 1, this.addOrderCourse)
            }
            else {
                this.addOrderModel.courses.push(this.addOrderCourse)
            }
        }
        else {
            this.addOrderModel.courses.splice(editingIndex, 1, this.addOrderCourse)
            if (editingIndex != existingIndex) {
                this.addOrderModel.courses.splice(existingIndex, 1)
            }
        }

        if (this.selectedCourse.offline) {
            const selectedClass = this.selectedCourse.offline_data.classes.find((c) => c.id === this.selectedClassId)
            if (selectedClass) {
                this.selectedClasses[this.addOrderCourse.course_id] = selectedClass
                if (selectedClass.language === NoteLanguage.EITHER) {
                    this.addOrderModel.languages[this.addOrderCourse.course_id] = this.selectedLanguage
                }
                else {
                    delete this.addOrderModel.languages[this.addOrderCourse.course_id]
                }
            }
        }
        else if (this.selectedCourse.language === NoteLanguage.EITHER) {
            this.addOrderModel.languages[this.addOrderCourse.course_id] = this.selectedLanguage
        }

        this.resetPopup()

        this.addCourseDialogVisible = false
    }

    courseChanged() {
        const course = CourseData.getCourse(this.addOrderCourse.course_id)
        this.addOrderCourse.price = course.price
        this.addOrderCourse.note = this.addOrderCourse.price > 0
        this.selectedClassId = -1
    }

    priceChanged() {
        if (this.addOrderCourse.price <= 0)
            this.addOrderCourse.note = false
    }

    resetPopup() {
        this.addOrderCourse = new AddOrderCourse()
        this.selectedCourse = new Course()
        this.selectedClassId = -1
        this.addOrderError = ''
        this.editingCourseId = ''
    }

    editCourse(courseId: string) {
        this.editingCourseId = courseId
        this.addOrderCourse = Object.assign(new AddOrderCourse(), this.addOrderModel.courses.find((c) => c.course_id === courseId))
        this.selectedCourse = this.selectedCourses.find((c) => c._id === courseId) || new Course()
        this.selectedLanguage = this.addOrderModel.languages[courseId]

        if (this.selectedClasses[courseId]) {
            this.selectedClassId = this.selectedClasses[courseId].id
        }
        else {
            this.selectedClassId = -1
        }

        this.addCourseDialogVisible = true
    }

    deleteCourse(courseId: string) {
        const index = this.addOrderModel.courses.findIndex((c) => c.course_id === courseId)
        if (index !== -1) {
            this.addOrderModel.courses.splice(index, 1)
        }
        delete this.selectedClasses[courseId]
    }

    selectClass(offlineClass) {
        this.selectedClassId = offlineClass.id
        this.selectedLanguage = NoteLanguage.NONE
    }


}
